import React, { useState, useEffect } from 'react';

// Helper function to check WebP support
const checkWebPSupport = (callback) => {
    const canvas = document.createElement('canvas');
    // Check if the canvas context is available
    if (!canvas.getContext || !canvas.getContext('2d')) {
        callback(false);
        return;
    }

    // Check if WebP is supported
    const isWebPSupported = canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;

    callback(isWebPSupported);
};

const WebPImage = ({ srcWebP, srcFallback, alt, ...props }) => {
    const [supportsWebP, setSupportsWebP] = useState(null);

    useEffect(() => {
        checkWebPSupport((supports) => {
            setSupportsWebP(supports);
        });
    }, []);

    // Render nothing if the support check is not completed yet
    if (supportsWebP === null) {
        return null;
    }

    return (
        <img
            src={supportsWebP ? srcWebP : srcFallback}
            alt={alt}
            {...props}
        />
    );
};

export default WebPImage;
